.mo-boxc-container {
    background-color: #3CAF89;
    padding-top: calc(50 * (100vw / 320));
    padding-bottom: calc(50 * (100vw / 320));
    padding-right: calc(30 * (100vw / 320));
    padding-left: calc(30 * (100vw / 320));
    display: flex;
    flex-direction: column;
}

.mo-boxc-maintext {
    font-size: calc(20 * (100vw / 320));
    color: white;
    font-weight: 600;
    margin-bottom: calc(20 * (100vw / 320));
}

.mo-boxc-subtext {
    font-size: calc(17 * (100vw / 320));
    color: #16223b;
    font-weight: 900;
    line-height: 1.6;
}

.mo-boxc-contents {
    margin-top: calc(70 * (100vw / 320));
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: calc(14 * (100vw / 320));
    font-weight: 400;
    color: white;
    line-height: 1.5;
    margin-bottom: calc(10 * (100vw / 320));
}

.mo-boxc-items {
    margin-bottom: calc(15 * (100vw / 320));
}
