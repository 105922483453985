.box-a-container {
    padding-left: calc(400 * (100vw/1920));
    padding-right: calc(400 * (100vw/1920));
    height: 150px;
    background-color: #fdfdfd;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #16223B;
    font-size: max(calc(24 * (100vw/1920)), 18px);
    font-weight: 900;
    line-height: 1.8;
    letter-spacing: 0.5px;
}

.blue-text {
    color: #5b8def;
    font-weight: 900;
}