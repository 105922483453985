.box-d-container {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
}

.box-d-left-box {
    width: calc(960 * (100vw / 1920));
    height: calc(600 * (100vw / 1920));
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.box-d-left-contents {
    margin-left: calc(400 * (100vw / 1920));
    display: flex;
    align-items: center;
    margin-top: calc(40 * (100vw / 1920));
    margin-bottom: calc(40 * (100vw / 1920));
}

.boxd-left-top-text {
    padding-left: calc(40 * (100vw / 1920));
    font-size: max(calc(18 * (100vw / 1920)), 12px);
    font-weight: 500;
    margin-bottom: calc(10 * (100vw / 1920));
    color: #16223B;
}

.boxd-left-bot-text {
    padding-left: calc(40 * (100vw / 1920));
    font-size: max(calc(24 * (100vw / 1920)), 16px);
    font-weight: 800;
    color: #16223B;

}

.box-d-right-box {
    width: calc(960 * (100vw / 1920));
    height: calc(600 * (100vw / 1920));
    background: linear-gradient(to bottom, #3C5DA1, #16223b);
    color: white;
    padding-top: calc(30 * (100vw / 1920));
    padding-right: calc(200 * (100vw / 1920));
    padding-left: calc(50 * (100vw / 1920));
}

.boxd-right-top-text {
    font-size: calc(32 * (100vw / 1920));
    font-weight: 900;
    margin-bottom: calc(50 * (100vw / 1920));
}

.boxd-right-middle-text {
    font-size: max(calc(18 * (100vw / 1920)), 10px);
    line-height: 1.8;
}

.line-jump {
    height: calc(40 * (100vw / 1920));
}

.boxd-right-button {
    background-color: #3CAF89;
    color: white;
    border: none;
    border-radius: calc(15 * (100vw / 1920));
    width: max(calc(200 * (100vw / 1920)), 140px);
    height: max(calc(45 * (100vw / 1920)), 25px);
    font-size: max(calc(18 * (100vw / 1920)), 10px);
    font-weight: 600;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    margin-top: calc(68 * (100vw / 1920));
    letter-spacing: 0.3px;
    cursor: pointer;

}