.mo-footer-container {
    background-color: #34464B;
    padding-top: calc(30 * (100vw / 320));
    padding-bottom: calc(30 * (100vw / 320));
    padding-right: calc(30 * (100vw / 320));
    padding-left: calc(30 * (100vw / 320));
}

.mo-footer-text {
    color: white;
    font-size: calc(12 * (100vw / 320));
    font-weight: 400;
    line-height: 2.3;
}

.mo-footer-img {
    margin-bottom: calc(20 * (100vw / 320));
}