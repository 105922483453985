.footer-container {
    background-color: #34464B;
    padding-left: calc(400 * (100vw / 1920));
    padding-right: calc(400 * (100vw / 1920));
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: calc(200 * (100vw / 1920));
}

.footer-left-box {
    font-size: max(calc(14 * (100vw / 1920)), 10px);
    color: white;
    line-height: 2;
    font-weight: 400;
}


.footer-right-box {
    font-size: max(calc(14 * (100vw / 1920)), 10px);
    color: white;
    line-height: 2;
    text-align: right;
    font-weight: 400;
}