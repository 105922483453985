.box-e-top-container {
    height: calc(600 * (100vw / 1920));
    padding-left: calc(400 * (100vw / 1920));
    padding-right: calc(400 * (100vw / 1920));
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #3CAF89;
}

.box-e-top-text {
    display: flex;
    align-items: center;
}

.box-e-top-main {
    font-size: calc(36 * (100vw / 1920));
    color: white;
    font-weight: 800;
}

.box-e-top-sub {
    margin: auto;
    font-size: calc(24 * (100vw / 1920));
    color: #16223b;
    font-weight: 600;
}

.box-e-top-contents {
    display: flex;
    justify-content: space-between;
}

.box-e-top-items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    font-size: calc(20 * (100vw / 1920));
    line-height: 1.7;
}

.box-e-top-img {
    margin-top: calc(100 * (100vw / 1920));
    margin-bottom: calc(30 * (100vw / 1920));
}

.box-e-bot-container {
    padding-left: calc(400 * (100vw / 1920));
    height: calc(400 * (100vw / 1920));
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #16223b;
}

.box-e-bot-maintext {
    font-size: calc(40 * (100vw / 1920));
    font-weight: 800;
    margin-bottom: calc(40 * (100vw / 1920));
}

.box-e-bot-subtext {
    font-size: calc(20 * (100vw / 1920));
    line-height: 1.8;
    font-weight: 500;
}

.box-e-bot-footbox {
    display: flex;
    margin-top: calc(20 * (100vw / 1920));
    align-items: center;
}

.box-e-bot-foottext {
    font-size: 24px;
    padding-left: 15px;
    font-weight: 600;
    padding-bottom: 3px;
}