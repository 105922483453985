.box-c-top-container {
    display: flex;
    padding-left: calc(400 * (100vw/1920));
    padding-right: calc(400 * (100vw/1920));
    margin-top: 100px;
    height: 150px;
    justify-content: space-between;
    background-color: #fdfdfd;
    align-items: center;
    font-size: max(calc(23 * (100vw/1920)), 12px);
    font-weight: 600;
    line-height: 2;
    color: #5b8def;
}

.box-c-top-button {
    background-color: #5b8def;
    color: white;
    border: none;
    border-radius: calc(10 * (100vw / 1920));
    width: max(calc(230 * (100vw / 1920)), 200px);
    height: max(calc(60 * (100vw / 1920)), 50px);
    font-size: max(calc(22 * (100vw / 1920)), 18px);
    font-weight: 800;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    letter-spacing: 2px;
    cursor: pointer;
}

.box-c-bot-container {
    display: flex;
    padding-left: calc(400 * (100vw/1920));
    padding-right: calc(400 * (100vw/1920));
    margin-top: 100px;
    justify-content: center;
    font-size: max(calc(23 * (100vw/1920)), 18px);
    font-weight: 800;
    line-height: 2;
    letter-spacing: 1px;
    color: #16223B;
}