.mo-main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(550 * (100vw/320));
    background-image: url('./../imgs/mobile-main-img.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.mo-header-container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding-top: calc(10 * (100vw/320));
    padding-left: calc(10 * (100vw/320));
    padding-right: calc(10 * (100vw/320));
}

.mo-header-text {
    color: #16223B;
    font-weight: 600;
    font-size: calc(18 * (100vw/320));
}

.mo-main-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: calc(90 * (100vw/320));
    font-size: calc(22 * (100vw/320));
    line-height: 2;
    font-weight: 800;
}

.mo-main-button {
    background-color: #5b8def;
    color: white;
    border: none;
    border-radius: calc(10 * (100vw / 320));
    width: calc(230 * (100vw / 320));
    height: calc(50 * (100vw / 320));
    font-size: calc(18 * (100vw / 320));
    font-weight: 800;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    margin-top: calc(90 * (100vw/320));
}