.box-b-top-container {
    display: flex;
    margin-left: calc(400 * (100vw/1920));
    margin-right: calc(400 * (100vw/1920));
    margin-top: 60px;
    height: 400px;
    justify-content: space-between;
    position: relative;
}

.box-b-text-top {
    padding-left: 0px;
    position: absolute;
    z-index: 1;
    color: #16223B;
    background-color: rgba(255, 255, 255, 0.9);

}

.box-b-text-top-main {
    font-size: 20px;
    font-weight: 800;
    margin-bottom: 60px;
    color: #5b8def;
}

.box-b-text-top-sub {
    margin-top: 15px;
    font-size: 30px;
    font-weight: 800; 
}


.box-b-img-top {    
    position: absolute;
    right: 0px;
}

.box-b-bot-container {
    display: flex;
    margin-left: calc(400 * (100vw/1920));
    margin-right: calc(400 * (100vw/1920));
    justify-content: space-between;
    position: relative;
    height: 700px;
}

.box-b-img-bot {    
    position: absolute;
    left: 0px;
}

.box-b-text-bot {
    right: 0px;
    position: absolute;
    bottom: 0px;
    z-index: 1;
    color: #16223bd7;
    text-align: right;
    font-weight: 600;
    font-size: 19px;
    line-height: 2;
    letter-spacing: 0.3px;
    background-color: rgba(255, 255, 255, 0.9);
}

