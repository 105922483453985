.mo-boxb-container {
    margin-top: calc(40 * (100vw / 320));
    display: flex;
    flex-direction: column;
    background: linear-gradient(to bottom, #3C5DA1, #16223b);
    color: white;
    justify-content: space-around;
    padding-top: calc(30 * (100vw / 320));
    padding-bottom: calc(30 * (100vw / 320));
    padding-right: calc(30 * (100vw / 320));
    padding-left: calc(30 * (100vw / 320));
}

.mo-boxb-maintext {
    font-size: calc(24 * (100vw / 320));
    font-weight: 600;
}

.mo-boxb-subtext {
    font-size: calc(12 * (100vw / 320));
    line-height: 1.6;
    font-weight: 400;
    margin-top: calc(30 * (100vw / 320));
}