.header-container {
    height: calc(100 * (100vw/1920));
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}

.header-left-box {
    margin-left: calc(400 * (100vw/1920));
}

.header-right-box {
    margin-right: calc(400 * (100vw/1920));
    display: flex;
    font-size: calc(20 * (100vw/1920));
    font-weight: 400;
    text-decoration: none;
    color: #16223B;
}

.hrb-a {
    padding-right: calc(40 * (100vw/1920));
    text-decoration: none;
    color: #16223B;
}

.hrb-b {
    padding-right: calc(40 * (100vw/1920));
    text-decoration: none;
    color: #16223B;
}

.hrb-c {
    text-decoration: none;
    color: #16223B;
}

.main-container {
    height: calc(950 * (100vw/1920));
    background-image: url('./../imgs/main-img.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

}

.main-text {
    font-size: calc(36 * (100vw/1920));
    font-weight: 900;
    color: #16223B;
    margin-top: calc(13 * (100vw/1920));
}

.main-text-box {
    padding-left: calc(400 * (100vw/1920));
    margin-top: calc(300 * (100vw/1920));
}

.main-button {
    margin-left: calc(400 * (100vw/1920));
    margin-top: calc(30 * (100vw/1920));
    background-color: #5b8def;
    color: white;
    border: none;
    border-radius: calc(10 * (100vw / 1920));
    width: calc(270 * (100vw / 1920));
    height: calc(60 * (100vw / 1920));
    font-size: calc(22 * (100vw / 1920));
    font-weight: 900;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    cursor: pointer;

}