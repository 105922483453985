.mo-boxd-container {
    padding-top: calc(50 * (100vw / 320));
    padding-bottom: calc(30 * (100vw / 320));
    padding-right: calc(30 * (100vw / 320));
    padding-left: calc(30 * (100vw / 320));
}

.mo-boxd-top-text {
    color: #16223b;
    font-size: calc(24 * (100vw / 320));
    font-weight: 600;
    margin-bottom:  calc(20 * (100vw / 320));
}

.mo-boxd-middle-text {
    color: #16223b;
    font-size: calc(16 * (100vw / 320));
    line-height: 1.6; 
}

.mo-boxd-foot-box {
    display: flex;
    align-items: center;
    margin-top: calc(10 * (100vw / 320));
}

.mo-boxd-bot-text {
    color: #16223b;
    padding-left: calc(10 * (100vw / 320));
    font-weight: 600;
    font-size: calc(14 * (100vw / 320));
}