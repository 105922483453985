.mo-boxa-container {
    display: flex;
    flex-direction: column;
}

.mo-boxa-contents {
    display: flex;
    margin-top: calc(50 * (100vw/320));
    margin-left: calc(30 * (100vw/320));

}

.mo-boxa-top-text {
    margin-top: calc(5 * (100vw/320));
    margin-left: calc(30 * (100vw/320));
    font-size: calc(14 * (100vw/320));
    color: #16223B;
    margin-bottom: calc(6 * (100vw/320));
}

.mo-boxa-bot-text {
    margin-left: calc(30 * (100vw/320));
    font-size: calc(18 * (100vw/320));
    color: #16223B;
    font-weight: 600;
}